<template>
  <li @mouseenter="hover = true" @mouseleave="hover = false" :class="[{ active, hover, 'has-sublevel': $slots.level }]">
    <a class="level-item" v-bind="$attrs"><slot/></a>

    <navigation-main-level v-if="$slots.level" :level="level">
      <slot name="level"/>
    </navigation-main-level>
  </li>
</template>

<script>
import NavigationMainLevel from '@/components/layout/NavigationMainLevel'

export default {
  name: 'MainNavigationLevelItem',
  inheritAttrs: false,
  components: {
    NavigationMainLevel
  },
  props: {
    devhover: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      level: this.$parent.levelCount,
      hover: this.devhover
    }
  }
}
</script>

<style lang="scss">
// .is-expanded state for mobile viewport is managed in NavigationMain.vue

// desktop
$level-item-padding-y: $spacer * 0.3 !default;
$level-item-padding-x: 0 !default;
$level-item-font-weight: inherit !default;
$level-item-font-size: $font-size-base !default;
$level-item-color: $body-color !default;

$level-item-hover-font-weight: inherit !default;
$level-item-hover-font-size: $level-item-font-size !default;
$level-item-hover-color: $primary !default;

$level-item-active-font-weight: $font-weight-bold !default;
$level-item-active-font-size: $level-item-font-size !default;
$level-item-active-color: $level-item-color !default;

$level-1-item-padding-y: $spacer * 0.5 !default;
$level-1-item-padding-x: $spacer * 0.75 !default;

$level-2-item-widths: (
  xs: auto,
  sm: auto,
  md: auto,
  lg: 33%,
  xl: 25%
) !default;

// mobile
$level-item-mobile-breakpoint: $mobile-breakpoint !default;

$level-item-mobile-padding-y: $level-item-padding-y !default;
$level-item-mobile-padding-x: $level-item-padding-x !default;
$level-item-mobile-font-weight: $level-item-font-weight !default;
$level-item-mobile-font-size: $level-item-font-size !default;
$level-item-mobile-color: $level-item-color !default;

$level-item-mobile-hover-font-weight: $level-item-hover-font-weight!default;
$level-item-mobile-hover-font-size: $level-item-hover-font-size !default;
$level-item-mobile-hover-color: $level-item-hover-color !default;

$level-item-mobile-active-font-weight: $level-item-active-font-weight !default;
$level-item-mobile-active-font-size: $level-item-active-font-size !default;
$level-item-mobile-active-color: $level-item-active-color !default;

$level-1-item-mobile-padding-y: $level-item-mobile-padding-y !default;
$level-1-item-mobile-padding-x: $level-item-mobile-padding-x !default;
$level-1-item-mobile-font-size: $font-size-lg !default;

.mainnavigation {
  .mainnavigation-menu {
    li {
      > .level-item {
        display: block;
        position: relative;
        padding: $level-item-padding-y $level-item-padding-x;
        font-weight: $level-item-font-weight;
        font-size: $level-item-font-size;
        color: $level-item-color;
        text-decoration: none;
      }

      > .level {
        display: none;
      }

      &.hover {
        > .level-item {
          font-weight: $level-item-hover-font-weight;
          font-size: $level-item-hover-font-size;
          color: $level-item-hover-color;
        }

        > .level {
          display: block;
        }
      }

      &.active {
        > .level-item {
          font-weight: $level-item-active-font-weight;
          font-size: $level-item-active-font-size;
          color: $level-item-active-color;
          cursor: default;
        }
      }
    }

    .level-1 > ul {
      margin-left: $level-1-item-padding-x * -1;
      margin-right: $level-1-item-padding-x * -1;

      > li {
        > .level-item {
          position: relative;
          padding: $level-1-item-padding-y $level-1-item-padding-x;
        }

        &.hover {
          &.has-sublevel {
            > .level-item {
              &:after {
                display: block;
                content: '';
                position: absolute;
                top: 100%;
                left: -200%;
                right: -200%;
                height: 300%;
              }
            }
          }
        }
      }
    }

    .level-2 > ul {
      > li {
        position: relative;
      }
    }

    .level-3 {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
    }

    @each $key, $width in $level-2-item-widths {
      @include media-breakpoint-up($key) {
        .level-2 > ul {
          > li {
            margin-left: if($width == auto, $width, 100 - ($width * 2));
            width: $width;
          }
        }
      }
    }

    @include media-breakpoint-down($level-item-mobile-breakpoint) {
      li {
        > .level-item {
          display: inline-block;
          padding: $level-item-mobile-padding-y $level-item-mobile-padding-x;
          font-weight: $level-item-mobile-font-weight;
          font-size: $level-item-mobile-font-size;
          color: $level-item-mobile-color;
        }

        > .level {
          display: block;
        }

        &.hover {
          > .level-item {
            font-weight: $level-item-mobile-hover-font-weight;
            font-size: $level-item-mobile-hover-font-size;
            color: $level-item-mobile-hover-color;
          }
        }

        &.active {
          > .level-item {
            font-weight: $level-item-mobile-active-font-weight;
            font-size: $level-item-mobile-active-font-size;
            color: $level-item-mobile-active-color;
          }
        }
      }

      .level-1 > ul {
        margin-left: 0;
        margin-right: 0;

        > li {
          > .level-item {
            position: static;
            padding: $level-1-item-mobile-padding-y $level-1-item-mobile-padding-x;
            font-size: $level-1-item-mobile-font-size;
          }

          &.hover {
            &.has-sublevel {
              > .level-item {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }

      .level-2 > ul {
        > li {
          position: static;
        }
      }

      .level-3 {
        position: static;
        width: auto;
      }
    }
  }
}
</style>
